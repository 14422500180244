#header {
  background: radial-gradient(50% 50% at 50% 50%, #f0fff1 0%, rgba(240, 255, 241, 0) 100%);
}
* {
  font-family:
    'Inter',
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

h1,
h2,
h3 {
  @apply text-black;
}
@import './blog.scss';
@import './password-generator.scss';

p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  color: #555555;
}

p,
li {
  @apply text-md text-gray mb-0;
}

b,
strong {
  @apply text-black;
}

.bg-block {
  background-color: #f5f5f5;
}

.text-secondary-45 {
  color: #858585;
}
.text-secondary-65 {
  color: #555656;
}
.text-white-secondary {
  color: #bfbfbf;
}

.triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent rgb(38 131 52) transparent;
  transform: rotate(0deg);
}

.primary-btn {
  @apply bg-primary rounded-lg hover:bg-primary-HOVER text-white sm:text-xl text-lg font-semibold py-2.5 px-9 cursor-pointer inline-block transition-colors text-center;
}
.primary-btn-outline {
  @apply bg-white border-solid rounded-lg hover:bg-primary hover:text-white text-primary sm:text-xl text-lg font-semibold py-2.5 px-9 transition-colors cursor-pointer border border-primary inline-block text-center;
}
.btn-small {
  @apply py-2 px-6;
}

.block-border {
  @apply border rounded-3xl border-solid;
  border-color: #d9d9d9;
}

.border-divider {
  border-color: #e5e5e5;
}

.section-container {
  @apply max-w-7xl px-6 mx-auto;
}

.cta-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%),
    linear-gradient(180deg, #d4e6d6 0%, #c6f1cb 100%);
}

.fade-in {
  animation: fadeIn 0.5s forwards ease-in;
}

.fade-out {
  animation: fadeOut 0.5s forwards ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(22, 92, 35, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(22, 92, 35, 0);
  }
}

hr {
  @apply border-t-0 border-divider;
}

#navbar,
#subnavbar {
  @apply top-0 transition-transform bg-white;

  > div {
    @apply bg-white;
  }

  .logo-white {
    @apply opacity-0;
  }
  .white-btn {
    @apply text-black;
  }

  .vr {
    @apply mx-3 h-8 border-solid border-l border-r-0 border-t-0 border-b-0;
    border-color: #bfbfbf;
  }

  .nav-item {
    .nav-item-label {
      @apply text-md text-gray-700 font-semibold mb-0 py-5 px-5 cursor-default transition-colors;
      span {
        font-size: 12px;
        margin-left: 3px;
      }
    }

    .nav-item-label:hover {
      @apply text-primary;
    }

    .nav-item-label:hover ~ .nav-item-content {
      @apply visible opacity-100;
    }

    .nav-item-content:hover {
      @apply visible opacity-100;
    }

    .nav-item-content {
      @apply absolute z-50 top-16 transition-all opacity-0 invisible duration-300 ease-in-out bg-transparent;
      border-color: #f0f0f0;

      a:hover {
        .label {
          @apply text-primary font-semibold;
        }
      }
      a {
        .label {
          @apply text-black text-lg mb-0 transition-all;
        }
      }
    }

    .nav-item-content > div {
      max-height: calc(100dvh - 64px);
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .nav-item-content > div::-webkit-scrollbar {
      display: none;
    }
  }
}

#navbar {
  @apply z-50;
}

#subnavbar {
  @apply z-40 shadow-md bg-white;
}

#navbar.scrolled {
  @apply bg-white shadow-md;

  // @apply translate-y-4;

  // > div {
  //   @apply bg-primary rounded-full;
  // }

  // .logo-white {
  //   @apply opacity-100;
  // }
  // .white-btn {
  //   @apply text-primary;
  // }
  // .vr {
  //   border-color: transparent;
  // }
  // .menu-icon {
  //   color: white;
  // }
  // .nav-item {
  //   .nav-item-label {
  //     @apply text-white;
  //   }
  // };
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit-based browsers */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

strong {
  @apply font-semibold;
}

.secret-pricing {
  * {
    font-family: 'Inter', sans-serif !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #268334;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    &::before {
      display: none;
    }
  }
  .ant-table-cell {
    padding-left: 24px !important;
    vertical-align: top;
    border-inline-end: none !important;
    &:last-child {
      border-inline-end: 1px solid #f0f0f0 !important;
    }
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 12px;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 12px;
  }
  .ant-table-wrapper table {
    border-radius: 12px 12px 0 0;
  }

  &.secret-pricing-banner {
    background-image: url('../../../public/images/secrets/pricing/bg-banner.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .switch-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 380px;
      height: 52px;
      border-radius: 10000px;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid#d0d5dd;
      background: #f8f9fc;
      box-shadow: 0px 4px 8.3px 0px rgba(61, 61, 61, 0.2) inset;
    }

    .switch .toggle {
      position: absolute;
      background: white;
      border-radius: 10000px;
      transition: all 0.3s ease;
      width: 50%;
      height: 100%;
    }

    .switch .toggle.left {
      left: 0;
      background-color: #ffffff;
      border-right: 1px solid #d0d5dd;
    }

    .switch .toggle.right {
      left: 50%;
      background-color: #ffffff;
      border-left: 1px solid #d0d5dd;
    }

    .switch span {
      position: absolute;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #667085;
      transition: color 0.3s ease;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      padding: 12px 20px;
    }

    .switch span.personal {
      left: 0;
    }

    .switch span.business {
      right: 0;
    }

    .switch span.active {
      color: #268334;
      font-weight: 700;
    }
  }

  .overview-plan {
    td {
      @apply md:pt-6 md:pl-6 md:pr-6 pt-2 pl-2 pr-2 align-middle md:align-top;
      &:first-child {
        @apply lg:w-[50%] w-[65%];
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .ant-slider-track {
      background-color: #23772f;
      height: 8px;
      border-radius: 9999px;
    }
    .ant-slider-rail {
      background-color: #eaecf0;
      height: 8px;
      border-radius: 9999px;
    }
    .ant-slider-horizontal .ant-slider-handle {
      inset-block-start: 3px;
    }
  }
}

.hover-group {
  .hover-group-item-visible {
    @apply transition-opacity;
    opacity: 0;
  }
}

.hover-group:hover {
  .hover-group-item-visible {
    opacity: 1;
  }
}

// Secret download

.secret-download {
  &.secret-download-banner {
    background-image: url('../../../public/images/secrets/download/bg-banner.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .secret-download-solution {
      box-shadow: 0px 1.469px 2.938px 0px rgba(16, 24, 40, 0.05);
    }
  }
  &.secret-download-sdk {
    .content {
      &__title {
        &--macos {
          span {
            background: linear-gradient(91deg, #fa6866 78.85%, #fc8050 91.55%, #ffa907 101.21%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &--windows {
          span {
            color: #006dce;
          }
        }
        &--linux {
          span {
            background: linear-gradient(51deg, #ffa63f 41.89%, #fff500 124.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .ant-segmented {
      max-width: 100%;
      padding: 6px;
      background-color: #f9fafb;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      @apply sm:overflow-x-auto;
      .ant-segmented-group {
        max-width: 100%;
      }
      .ant-segmented-item-label {
        padding: 8px 20px;
        @apply text-sm md:text-md md:py-2 md:px-5 py-1 px-3;
        line-height: 28px;
        color: #667085;
        font-weight: 600;
        filter: grayscale(100);
      }
      .ant-segmented-item-selected {
        box-shadow:
          0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
        .ant-segmented-item-label {
          color: #344054;
          filter: grayscale(0);
        }
      }
    }
  }
}

.secret-features {
  &.secret-features-banner {
    background-image: url('../../../public/images/sm/features/bg-banner.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .secret-download-solution {
      box-shadow: 0px 1.469px 2.938px 0px rgba(16, 24, 40, 0.05);
    }
  }
}

.image-popup {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close {
    @apply text-primary;
  }
}

.pm-pricing-personal {
  .ant-segmented {
    max-width: 100%;
    padding: 6px;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    @apply sm:overflow-x-auto;
    .ant-segmented-group {
      max-width: 100%;
    }
    .ant-segmented-item-label {
      padding: 8px 20px;
      @apply text-sm md:text-md md:py-2 md:px-5 py-1 px-3;
      line-height: 28px;
      color: #667085;
      font-weight: 600;
      filter: grayscale(100);
    }
    .ant-segmented-item-selected {
      box-shadow:
        0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
      .ant-segmented-item-label {
        color: #344054;
        filter: grayscale(0);
      }
    }
  }
}
