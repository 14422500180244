@import './base/_reset';

// sass-utils
@import './sass-utils/_fonts';

// vendors
@import './vendors/_tailwind';
@import './vendors/_antd';

@import './pages/index.scss';

// base

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth !important;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.animate {
  animation: animate 0.8s;
}

.animateRTL {
  animation: animateRTL 0.8s;
}
.animateLTR {
  animation: animateLTR 0.8s;
}

@keyframes animate {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateLTR {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animateRTL {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.bg-default {
  background-color: rgba(250, 250, 250, 1);
}

.border-default {
  border-color: rgba(240, 242, 245, 1);
}

.ant-progress-text {
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-carousel .slick-dots {
  z-index: 5;
}

.animation-fade-in {
  animation: fadeIn ease 0.4s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.common-icon {
  border: 1px rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
  @apply flex items-center justify-center rounded-[10px] w-full h-full;
}

.form-request-download-file {
  button {
    &:disabled {
      color: #ffffff;
      background-color: #268334;
      border-color: #268334;
    }
  }
  .ant-form-item .ant-form-item-label label {
    padding-top: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .ant-input {
    background-color: transparent;
    border-color: #333741;
    border-radius: 8px !important;
    @apply text-md px-[14px] rounded-lg;
    color: #ffffff;
  }
  .ant-select {
    .ant-select-selector {
      background-color: transparent !important;
      height: 44px !important;
      padding-left: 14px;
      padding-right: 14px;
      border-color: #333741;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 8px !important;
      .ant-select-selection-search {
        inset-inline-start: 14px !important;
      }
      .ant-select-selection-search-input {
        height: 44px !important;
        color: #94969c !important;
      }
      .ant-select-selection-item {
        color: #ffffff;
      }
    }
  }
  .ant-select-arrow {
    svg {
      color: #ffffff !important;
    }
  }
  .ant-checkbox-inner {
    background-color: transparent;
  }

  .form-input-phone {
    .ant-row.ant-form-item-row {
      width: 100%;
    }
    .ant-col.ant-form-item-control {
      width: 100%;
      .ant-form-item-control-input {
        width: 100%;
        .ant-form-item-control-input-content {
          display: flex;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .ant-input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.locker-report-header-drawer {
  .anticon {
    color: #ffffff;
  }
}

.main-locker-report-content {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../../public/BackgroundLockerReport.jpg');
    background-size: cover;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-image: url('../../public/BackgroundLockerReportGradient.svg');
    background-size: cover;
  }
}

.toast-success {
  width: 400px !important;
  padding: 16px;
  border-radius: 16px !important;
  .ant-notification-notice-with-icon {
    margin-left: 36px;
  }
  .ant-notification-notice-icon {
    position: absolute;
    left: 6px;
    top: 6px;
  }
  .ant-notification-notice-message {
    margin-left: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #101828 !important;
  }
  .ant-notification-notice-description {
    margin-left: 0 !important;
    font-size: 14px !important;
    color: #344054 !important;
  }
}

.password-manager-pricing-table {
  .ant-collapse {
    border: 1px solid #eaecf0;
    border-radius: 12px;
    overflow: hidden;
    .ant-collapse-header {
      padding: 24px !important;
      background-color: #f9fafb;
    }
    .ant-collapse-content-box {
      padding: 0;
      padding-block: 0 !important;
    }
  }
  .table-info {
    td {
      @apply md:pl-6 md:pr-6 pl-2 pr-2 align-middle md:align-middle;
      @apply lg:w-1/4 w-1/2;
      &:first-child {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

.password-manager-pricing-faq {
  .ant-collapse {
    .ant-collapse-item {
      border-radius: 16px;
      @apply p-8 lg:p-6 md:p-4;
      &.ant-collapse-item-active {
        background-color: #f9fafb;
      }
      .ant-collapse-header {
        padding: 0;
      }
    }
  }
}
