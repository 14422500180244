.ant-form-item .ant-form-item-label {
  @apply block;
  label {
    align-items: start;
    padding-top: 12px;
    height: auto !important;
    &::before {
      padding-top: 6px;
    }
  }
}

.ant-form-item .ant-form-item-label > label {
  @apply h-full text-black after:hidden text-md;
}
