.ant-select {
  border-radius: 4px !important;
  .ant-select-selector {
    @apply text-md text-black border-[#DADEE3] placeholder:text-[#858C94] h-auto;
  }
}

.ant-select.ant-select-single:not(.ant-select-customize-input) {
  @apply h-auto;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @apply h-auto py-[10px] px-6;
}

.ant-select-show-search .ant-select-selector {
  @apply px-6;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  @apply h-[48px];
}

.ant-select .ant-select-selection-placeholder {
  @apply text-md text-[#858C94];
}

.ant-select-selection-item {
  @apply text-md;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 24px;
}

// Select multiple search

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  @apply h-[52px];
}
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-start: 24px;
  inset-inline-end: 24px;
}
.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0px;
}
