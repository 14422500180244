.password-generator-usecase {
  * {
    font-family: "Inter", sans-serif !important;
  }
  .ant-table-wrapper .ant-table {
    border-radius: 12px 12px 0;
  }
  .ant-table-wrapper .ant-table-container {
    border-radius: 12px;
    border-start-start-radius: 12px;
    border-start-end-radius: 12px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #1b5d25;
    color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    &::before {
      display: none;
    }
  }
  .ant-table-cell {
    padding-left: 24px !important;
    vertical-align: top;
    font-weight: 500;
    &:last-child {
      border-inline-end: 1px solid #f0f0f0 !important;
    }
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 12px;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 12px;
  }
  .ant-table-wrapper table {
    border-radius: 12px 12px 0 0;
  }
}

.custom-table-using-columns > div + div {
  border-style: solid;
  border-right-width: 0px;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-color: #eaecf0;
}
