.ant-input {
  border-radius: 4px !important;
  @apply text-sm py-[10px]  px-6 text-black border-[#DADEE3] placeholder:text-[#858C94];
  &.ant-input-lg {
    @apply py-[11px];
  }
  &.ant-input-sm {
    @apply py-[9px];
  }
}

.ant-picker-input {
  input {
    @apply placeholder:text-[#858C94] text-md;
  }
}

.ant-input-affix-wrapper {
  @apply text-sm py-[12px] px-6 text-black border-[#DADEE3] placeholder:text-[#858C94];
}
