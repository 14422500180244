.blog-banner {
  background-image: url("../../../public/images/blog/bg-banner.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.locker-blog {
  scroll-behavior: smooth;
  * {
    font-family: "Inter" !important;
  }
  .main-blog-content {
    .rt-reading-time {
      display: none !important;
    }
    h2 {
      @apply text-display-sm font-semibold;
    }
    h3 {
      @apply text-display-xs font-semibold;
    }
    ul,
    ol {
      @apply pl-5;
    }
    p,
    li, div {
      @apply text-lg mb-2;
    }
    a {
      @apply text-primary hover:underline;
    }
    figure {
      max-width: 100% !important;
      width: 100% !important;
      display: block !important;
    }
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
      margin: 24px auto;
    }
    code {
      word-break: break-all;
      background-color: #f1f1f1;
      font-size: 16px;
      border-radius: 4px;
      padding: 0 2px;
    }
    table {
      border-collapse: collapse;
      th {
        padding: 16px 4px;
      }
    }
    table,
    td,
    th,
    td {
      border: 1px solid #e4e7ec;
      padding: 8px;
    }
  }
}

.form-subscribe-blog {
  .ant-input {
    border-radius: 8px !important;
  }
  .ant-input-affix-wrapper {
    padding: 10px 14px;
  }
}

.modal-subscribe {
  * {
    font-family: "Inter";
  }
  .ant-modal-content {
    padding: 24px;
    background-image: url("../../../public/images/blog/Mask.svg");
    background-position: top left !important;
    background-repeat: no-repeat;
    background-size: 230px;
  }
  .ant-modal-body {
    position: relative;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px !important;
    padding: 10px 14px;
    .ant-input-prefix {
      margin-right: 8px;
    }
  }
  label {
    font-weight: 500;
    line-height: 20px !important;
  }
}

.blog-category {
  .ant-segmented {
    max-width: 100%;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    @apply sm:overflow-x-auto;
    padding: 0;
    .ant-segmented-group {
      max-width: 100%;
      border-radius: 8px;
      gap: 8px;
    }
    .ant-segmented-item-label {
      padding: 10px 12px;
      @apply text-sm md:text-md;
      line-height: 28px;
      color: #667085;
      font-weight: 600;
      filter: grayscale(100);
    }
    .ant-segmented-item-selected {
      box-shadow:
        0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
      border-radius: 8px;
      .ant-segmented-item-label {
        color: #344054;
        filter: grayscale(0);
      }
    }
  }
}

.blog-pagination {
  .ant-pagination {
    display: flex;
    justify-content: center;
    .ant-pagination-prev {
      margin-right: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-next {
      margin-left: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-item {
      @apply lg:min-w-10 lg:min-h-10 min-w-[28px] rounded-full;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal-copied-password {
  .ant-modal-content {
    padding: 0;
  }
}

.copy-password-modal-content {
  background-image: url("../../../public/images/password-generator/BackgroundModalCopyPassword.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}
